@import '../../components/commonStyle/common.scss';
.my-alert{
  &.success{
    background-color: rgb(153, 216, 241);
  }
  z-index: 2000;
  position: fixed;
  top: 5px;
  width: 700px;
  @include media-breakpoint-down(md) {
    width: 90%;
  }
  height: 35px;
  background-color: red;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    span{
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      color: white;
      &:hover {
        color: #52585f;
        text-decoration: none;
      }
    }
  }
}
