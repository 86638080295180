@import '../../../../components/commonStyle/common.scss';
.section {
  height: 100vh;
  @include media-breakpoint-down(md) {
    height: 70vh;
  }
}
.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contents{
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  margin-top: 100px;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0;
}
.intro{
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #52585f;
  margin: 0 0 50px 0;
}
.images{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 50px 0;
}
.image{
  width: 33.33%;
  height: 309px;
  border-radius: 6px;
  background-color: #eeeeee;
}
.imgGene{
  width: 1176px;
  height: 418px;
  @include media-breakpoint-down(md) {
    width: 100%;
    height: 200px;
  }
}
.processes{
  display: flex;
  flex-direction: row;
  div{
    max-width: 238px;
    margin: 0 20px 0 0;
    padding: 12px 30px 12px 30px;
    border-radius: 10px;
    font-family: Avenir-Roman;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
  }
}
