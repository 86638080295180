.container{
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	img{
		width: 15px;
		height: 32px;
		&:hover{
			cursor: pointer;
		}
	}
}
.wrapper{
	width: 1200px;
	overflow: hidden;
}
.carousel{
	display: flex;
	width: 1200px;
	height: 400px;
	flex-direction: row;
	box-sizing: border-box;
	transition: transform 1s ease;
	img{
		flex-shrink: 0;
		width: 300px;
		height: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
    object-fit: contain;
	}
}
