@import '../../../commonStyle/common.scss';

.header-menu-container{
  & > .response-show:first-child{
    @include media-breakpoint-down(md) {
      margin-left: 0 !important;
      & div{
        font-size: 12px !important;
      }
    }
  }
  & > .response-show:nth-child(2), & > .response-show:nth-child(3), & > .response-show:nth-child(4), & > .response-show:nth-child(5){
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
  .header-login-show{
    @include media-breakpoint-down(md) {
      a {
        display: none;
      }
      margin-left: 25px !important;
      & div{
        font-size: 12px !important;
      }
    }
  }
}
