.container{
  display: flex;
  flex-direction: column;
}
.postHeader{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  background-image: linear-gradient(to left, rgba(248, 216, 206, 0.86), rgba(238, 163, 201, 0.86));
  box-sizing: border-box;
  .headerContent {
    max-width: 1140px;
    margin: 0 auto;
  }
  h1{
    
    font-family: Avenir;
    font-size: 42px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 120px 0 60px 0;
  }
  p{
    font-family: Avenir;
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
.contents{
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  max-width: 1140px;
  width: 90%;
  p {
    color: #52585f;
    font-size: 20px;
  }
  img {
    margin: auto;
    display: block;
    max-width: 40%;
    height: auto;
  }
}
.media-wrap {
  text-align: center;
}
.subTitle{
  text-transform: uppercase;
  padding: 20px 0;
  margin-bottom: 0px;
}
.name{
  color: #999;
  font-style: italic;
  margin-bottom: 20px;
  font-size: 14px;
  span {
    color: #000;
  }
}
