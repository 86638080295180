.container{
  padding: 5px 0 5px 0;
  margin: 0 0 70px 0;
  list-style-type: none;
  box-sizing: border-box;
  li{
    font-size: 20px;
    font-weight: 600;
    display: inline;
    color: #99d8f1;
    margin: 0 25px 0 25px;
    &:hover{
      cursor: pointer;
    }
  }
  a{
    outline: none;
  }
}
.previous, .next{

}
.next{
  font-size: 100px;
  margin: 0;
}
.active{
  font-size: 24px;
  color: pink;
}
