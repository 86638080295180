.container{
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
}
.radioBtnOuter{
  width: 18px;
  height: 18px;
  border-radius: 30px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioBtnInner{
  width: 12px;
  height: 12px;
  border-radius: 12px;
}
.selected{
  background-color: #c3c8cc;
}
.btnTxt{
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  margin: 0 0 0 10px;
}
