@import '../../../../components/commonStyle/common.scss';
.button{
  width: 200px;
  margin-right: 25px;
  @include media-breakpoint-down(md) {
    width: 90%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  height: 42px;
  border-radius: 5px;
  background-color: #99d8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  p{
    font-family: Avenir;
    font-size: 15px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
}
