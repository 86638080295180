.container{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdf8f8;
  padding: 100px 0;
}
.contents{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
  white-space: pre-line;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0;
}
.processContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.processBubble{
  width: 600px;
  height: 74px;
  border-radius: 46px;
  box-shadow: 0 2px 8px 0 rgba(238, 163, 201, 0.3);
  background-color: rgba(238, 163, 201, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    max-width: 521px;
    font-family: Avenir;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}
.iconNext{
  width: 35px;
  height: 40px;
  margin: 10px 0 10px 0;
}
