@import '../../../../../components/commonStyle/common.scss';
.container{
  width: 910px;
  padding: 0 60px 70px 60px;
  @include media-breakpoint-down(md) {
    width: 90%;
    padding: 0 10px 40px 10px;
  }
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  h1{
    
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0;
  }
  p{
    font-family: Avenir;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e679b1;
    margin: 30px 0 60px 0;
    padding: 0;
  }
}
.reference{
  width: 320px;
  height: 63px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    width: fit-content;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}
