@import '../../../../../components/commonStyle/common.scss';
.container{
  width: 910px;
  @include media-breakpoint-down(md) {
    width: 90%;
    padding: 0 10px 70px 10px;
  }
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0 60px 70px 60px;
  box-sizing: border-box;
  box-sizing: border-box;
  h1{
    
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0;
  }
}
.signatureWrapper{
  width: 790px;
  height: 196px;
  border-radius: 10px;
  border: solid 1.5px #e6e9eb;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.subtitle{
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
  padding: 0;
}
.clear{
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: dodgerblue;
  text-align: right;
  text-decoration: underline;
  margin: 10px 0 31px 0;
  padding: 0;
  &:hover{
    cursor: pointer;
  }
}
.cell{
  width: 365px;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    width: 100%;
    margin-bottom: 10px;
  }
}
.inputs{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  align-items: center;
}
.fieldTitle{
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
}
.highlightRequired{
  color: red;
}
