@import '../commonStyle/common';

.egg-process-container{
  .egg-process-item{
    @include media-breakpoint-down(md) {
      width: 300px !important;
      .description-step{
        font-size: 14px;
      }
    }
  }
}
