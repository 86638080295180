$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

.container{
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 159px;
  position: relative;
  @media(max-width: $phone) {
    margin: 20px 0;
    align-items: center;
  }
}

.logo{
  width: 164px;
  @media(max-width: $phone) {
    margin: auto;
  }
}
.address{
  font-family: AvenirNext;
  flex-wrap: wrap;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 15px 0 8px 0;
}
.copyRight{
  bottom: 0;
  left: 0;
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: 500;
  font-style: italic;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin-top: 30px;
  @media(max-width: $phone) {
    margin: 30px auto 0;
  }
}

.menuItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  @media(max-width: $phone) {
    margin: auto;
  }
}
.menuItemIcon{
  width: 18px;
  height: 18px;
  margin: 0 6px 0 0;
}
.menuItemValue{
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
}
.socialMediaIcon{
  width: 25px;
  height: 25px;
  &:hover{
    cursor: pointer;
  }
}
.socialMediasList{
  @media(max-width: $phone) {
    margin-top: 20px;
  }
  margin-left: -5px;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
