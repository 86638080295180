.container{
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0;
  }
}
.row{
  width: 100%;
  padding: 25px 0 40px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.attrCell{
  width: 230px;
  padding: 0 40px 0 60px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1;
}
.column{
  white-space: pre-line;
  margin: 0 18px 0 0;
  padding: 0;
  min-width: 140px;
  max-width: 160px;
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  position: relative;
  textarea{
    width: 140px;
    height: 60px;
    border-radius: 10px;
    border: solid 1.5px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #52585f;
    outline: none;
    padding: 5px 5px 0 5px;
    box-sizing: border-box;
  }
}
