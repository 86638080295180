@import '../../../../components/commonStyle/common.scss';

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdf8f8;
}
.contents{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
  white-space: pre-line;
  @include media-breakpoint-down(md) {
    a {
      display: none;
    }
  }
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0;
}
.intro{
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #52585f;
  margin: 0 0 50px 0;
}
.images{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 60px 0;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.image{
  width: 235px;
  height: 235px;
  object-fit: cover;
  @include media-breakpoint-down(md) {
    width: 100px;
    height: 100px;
    margin: 20px !important;
  }
}
.btn{
  padding: 14px 40px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  @include media-breakpoint-down(md) {
    max-width: 80%;
    margin: auto;
  }
  &:hover{
    cursor: pointer;
    background-color: #7dceef;
  }
  p{
    font-family: AvenirNext;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}
