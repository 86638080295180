.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contents{
  width: 100%;
  overflow: auto;
  display: flex;
  padding: 0 36px;
  box-sizing: border-box;
  flex-direction: column;
}
.title{
  margin: 99px 0 101px 0;
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
}
