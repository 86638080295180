@import '../../../../components/commonStyle/common.scss';
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contents{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 100px 0;
  white-space: pre-line;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0;
}
.introWrapper{
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 130px 0 130px;
  @include media-breakpoint-down(md) {
    padding: 0 20px 0 20px;
  }
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }
  opacity: 0;
  margin: 0;
  transition: opacity 0.5s ease;
  img{
    width: 590px;
    height: 379px;
    @include media-breakpoint-down(md) {
      width: 100%;
      height: 190px;
      margin-right: 0;
      margin-bottom: 10px;
    }
    border-radius: 6px;
    object-fit: scale-down;
    margin-right: 60px;
  }
}
.details{
  width: 530px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  h1{
    font-family: Avenir;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #e679b1;
    margin: 0 0 25px 0;
  }
}
.detailsBody{
  max-height: 180px;
  @include media-breakpoint-down(md) {
    max-height: fit-content;
  }
  overflow: hidden;
  font-family: Avenir;
  font-size: 22px;
  text-align: justify;
  color: #52585f;
}
.swiperContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 60px 0 0 0;
  @include media-breakpoint-down(md) {
    width: 375px;
  }
}
.swiperContent{
  margin: 10px auto;
  display: inherit;
}
.indicatorLeft, .indicatorRight{
  width: 15px;
  height: 32px;
  margin: auto 20px;
  &:hover{
    cursor: pointer;
  }
}
.indicatorRight{
  transform: rotate(180deg);
}
.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  &:hover{
    cursor: pointer;
  }
  img{
    width: 360px;
    height: 200px;
    @include media-breakpoint-down(md) {
      width: 260px;
    }
    object-fit: scale-down;
    border-radius: 6px;
    position: relative;
    margin: 0;
  }
}
.carouselWrapper{
  width: 1080px;
  @include media-breakpoint-down(md) {
    width: 260px;
  }
  overflow: hidden;
}
.carousel{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  transition: transform 1s ease;
}
.btn{
  padding: 5px 28px;
  @include media-breakpoint-down(md) {
    padding: 10px 28px;
  }
  border-radius: 5px;
  background-color: #99d8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  &:hover{
    cursor: pointer;
    background-color: #7dceef;
  }
  p{
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}
