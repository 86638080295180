.container{
  width: 1060px;
  border-radius: 30px;
  margin: 100px auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0;
  }
}
.row{
  width: 100%;
  padding: 15px 0 15px 0;
  display: flex;
}
.attrCell{
  width: 365px;
  padding: 0 0 0 60px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1;
  margin-right: 100px;
}
.valueCell{
  width: calc(100% - 525px);
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1;
}
