@import '../../../../../components/commonStyle/common.scss';
.container{
  width: 910px;
  padding: 0 60px 70px 60px;
  @include media-breakpoint-down(md) {
    width: 90%;
    padding: 0 10px 70px 10px;
  }
  overflow: hidden;
  border-radius: 30px;
  box-sizing: border-box;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0;
  }
}
.row{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  margin-bottom: 20px;
}
.cellMarginBottom{
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}
.shortCell{
  width: 223px;
}
.mediumCell{
  width: 365px;
}
.longCell{
  width: 100%;
}
.shortCell, .mediumCell{
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.cell{
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
}
.fieldTitle{
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
}
.highlightRequired{
  color: red;
}
.fieldContainer, .inputContainer, .dropdownContainer{
  height: 40px;
  border-radius: 5px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
}
.containerHighlight{
  border: solid 1px red;
}
.inputContainer, .dropdownContainer{
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  input{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    border: solid 1px transparent;
    outline: none;
    font-family: Avenir-Roman;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #52585f;
    padding: 10px;
    box-sizing: border-box;
    &::placeholder{
      font-family: Avenir-Roman;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b5b5c0;
    }
  }
}
.dropdownContainer{
  position: relative;
  img{
    position: absolute;
    width: 16px;
    height: 11px;
    right: 20px;
  }
  select{
    width: 100%;
    border-radius: 10px;
    background-color: transparent;
    border: solid 1px transparent;
    outline: none;
    -moz-appearance: window;
    -webkit-appearance: none;
    padding: 15px 10px;
    box-sizing: border-box;
    &::-ms-expand {
      display: none; /* Hide dropdown in IE 10 & 11 */
    }
    font-family: Avenir-Roman;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #52585f;
  }
}
.checkbox{
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  outline: none;
}
.checked{
  background-color: #c3c8cc;
}
.textArea{
  width: 100%;
  height: 84px;
  border-radius: 10px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  outline: none;
  padding: 15px 10px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
}
.textAreaHighLight{
  border: 1px solid red;
}
.othersContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.othersRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 0;
  box-sizing: border-box;
}
