.container{
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0;
  }
}
.cell{
  position: relative;
}
.fieldTitle{
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
}
.checkboxWrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.othersContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.othersRow{
  width: 100%;
  padding: 15px 60px 15px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.answersWrapper{
  display: flex;
  flex-direction: row;
}
