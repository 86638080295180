body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.LanguageMenu_container__1NlLz {
  height: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border: solid 1.5px #ffffff;
  border-radius: 6px; }

@media (max-width: 768px) {
  .language-menu-show {
    display: none !important; } }

@media (max-width: 768px) {
  .header-menu-container > .response-show:first-child {
    margin-left: 0 !important; }
    .header-menu-container > .response-show:first-child div {
      font-size: 12px !important; } }

@media (max-width: 768px) {
  .header-menu-container > .response-show:nth-child(2), .header-menu-container > .response-show:nth-child(3), .header-menu-container > .response-show:nth-child(4), .header-menu-container > .response-show:nth-child(5) {
    display: none !important; } }

@media (max-width: 768px) {
  .header-menu-container .header-login-show {
    margin-left: 25px !important; }
    .header-menu-container .header-login-show a {
      display: none; }
    .header-menu-container .header-login-show div {
      font-size: 12px !important; } }

@media (max-width: 768px) {
  .header-img-wrap img {
    max-width: 90px !important;
    max-height: 34px !important;
    margin: 0 12px !important; } }

.nav-container .logo {
  width: 165px;
  height: 45px; }
  @media (max-width: 576px) {
    .nav-container .logo {
      width: 120px;
      height: 32.73px; } }
  .nav-container .logo:hover {
    cursor: pointer; }

.nav-container .logo-black {
  -webkit-filter: invert(0) hue-rotate(180deg) contrast(2);
          filter: invert(0) hue-rotate(180deg) contrast(2); }
  @media (max-width: 768px) {
    .nav-container .logo-black {
      -webkit-filter: invert(1) hue-rotate(180deg) contrast(1);
              filter: invert(1) hue-rotate(180deg) contrast(1); } }

.nav-container .nav-link {
  color: #FFF; }

.nav-container .navbar-brand {
  color: #FFF; }

.nav-container .navbar-toggler {
  border: 1px solid #FFF;
  color: #FFF; }

.nav-container .btn {
  font-weight: 600; }

.nav-container .navbar-nav > li {
  margin-left: 1rem; }

.nav-container .navbar-nav .dropdown-menu {
  border-radius: 0;
  border: 0;
  width: 14rem;
  height: 28rem;
  background-color: rgba(0, 0, 0, 0.6); }
  .nav-container .navbar-nav .dropdown-menu .dropdown-item {
    height: 3.125rem;
    padding: 0 2.5rem;
    color: #999;
    font-size: 1.125rem;
    line-height: 3.125rem;
    cursor: pointer; }
    .nav-container .navbar-nav .dropdown-menu .dropdown-item:hover {
      background-color: #e61b47;
      color: #fff; }
  .nav-container .navbar-nav .dropdown-menu .secondary-menu {
    padding: 0.5rem 0;
    position: absolute;
    top: 0;
    left: 14rem;
    margin-left: -1px;
    border: 0;
    width: 14rem;
    height: 28rem;
    background-color: rgba(0, 0, 0, 0.6); }
    .nav-container .navbar-nav .dropdown-menu .secondary-menu .secondary-item {
      padding: 0.25rem 1.5rem;
      height: 3.125rem;
      padding: 0 2.5rem;
      color: #999;
      font-size: 1.125rem;
      line-height: 3.125rem;
      cursor: pointer; }
      .nav-container .navbar-nav .dropdown-menu .secondary-menu .secondary-item:hover {
        background-color: #e61b47;
        color: #fff; }
  @media (max-width: 768px) {
    .nav-container .navbar-nav .dropdown-menu .secondary-menu {
      display: none; } }

.white-back {
  -webkit-animation: arrows 0.6s ease 0s 1;
          animation: arrows 0.6s ease 0s 1;
  background: rgba(255, 255, 255, 0.85); }

@-webkit-keyframes arrows {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes arrows {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
  @media (max-width: 768px) {
    .white-back {
      background-image: linear-gradient(to left, rgba(248, 216, 206, 0.86), rgba(238, 163, 201, 0.86)); } }
  .white-back .nav-link {
    color: #2A2A2A; }
    @media (max-width: 768px) {
      .white-back .nav-link {
        color: #fff; } }
  .white-back .navbar-brand {
    color: #2A2A2A; }
    @media (max-width: 768px) {
      .white-back .navbar-brand {
        color: #fff; } }
  .white-back .navbar-toggler {
    border: 1px solid #FFF;
    color: #2A2A2A; }
    @media (max-width: 768px) {
      .white-back .navbar-toggler {
        color: #fff; } }
  .white-back .navbar-nav .nav-item {
    margin-left: 1rem; }

.dropdown-toggle::after {
  position: relative;
  vertical-align: middle !important; }

.SuccessStories_container__2Cvty {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.SuccessStories_title__1PDyv {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 60px 0; }

.SuccessStories_storiesContainer__3N96D {
  width: 82%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.SuccessStories_section__2hYJj {
  width: 100%; }

@media (max-width: 640px) {
  .SuccessStories_section__2hYJj {
    height: 100%; }
  .SuccessStories_storiesContainer__3N96D {
    width: 100%;
    display: block; } }

.SuccessStories_card__NiY0y {
  width: 360px;
  height: 437px;
  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
  margin: 20px auto;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-basis: auto;
          flex-basis: auto;
  padding: 0; }
  .SuccessStories_card__NiY0y:hover {
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08); }
  .SuccessStories_card__NiY0y img {
    width: 100%;
    height: 200px;
    background-color: grey;
    border-radius: 6px 6px 0 0;
    object-fit: cover; }

.SuccessStories_cardIntro__1Jajx {
  width: 100%;
  height: 57%;
  padding: 0 20px 0 20px;
  box-sizing: border-box; }
  .SuccessStories_cardIntro__1Jajx h1 {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e679b1;
    margin: 20px 0 20px 0; }
  .SuccessStories_cardIntro__1Jajx p {
    max-height: 100px;
    font-family: Avenir;
    font-size: 16px;
    text-align: left;
    line-height: normal;
    line-height: initial;
    color: #52585f;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3; }

.SuccessStories_footer__1XafJ {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 53px;
  background-color: #c3e8f7;
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  text-shadow: 0 1px 7px #212529;
  font-family: AvenirNext;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
  padding-left: 20px; }
  .SuccessStories_footer__1XafJ:hover {
    cursor: pointer;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
  .SuccessStories_footer__1XafJ p {
    height: 100%;
    margin-top: 15px; }

.SuccessStories_viewAll__2x9aG {
  font-family: AvenirNext;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #99d8f1;
  margin: 30px; }
  .SuccessStories_viewAll__2x9aG:hover {
    cursor: pointer;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
  @media (max-width: 768px) {
    .SuccessStories_viewAll__2x9aG {
      margin: 30px 0 50px 0; } }

.Footer_container__2Hfen {
  width: 100%;
  background-color: #a6a6b4;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 50px 0 34px 0;
  box-sizing: border-box;
  -webkit-justify-content: center;
          justify-content: center; }

.LeftMenu_container__1sTEm {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 159px;
  position: relative; }
  @media (max-width: 640px) {
    .LeftMenu_container__1sTEm {
      margin: 20px 0;
      -webkit-align-items: center;
              align-items: center; } }

.LeftMenu_logo__3ZCBx {
  width: 164px; }
  @media (max-width: 640px) {
    .LeftMenu_logo__3ZCBx {
      margin: auto; } }

.LeftMenu_address__heygK {
  font-family: AvenirNext;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 15px 0 8px 0; }

.LeftMenu_copyRight__3hEo2 {
  bottom: 0;
  left: 0;
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: 500;
  font-style: italic;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin-top: 30px; }
  @media (max-width: 640px) {
    .LeftMenu_copyRight__3hEo2 {
      margin: 30px auto 0; } }

.LeftMenu_menuItem__1L4vZ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 640px) {
    .LeftMenu_menuItem__1L4vZ {
      margin: auto; } }

.LeftMenu_menuItemIcon__2tFAT {
  width: 18px;
  height: 18px;
  margin: 0 6px 0 0; }

.LeftMenu_menuItemValue__13dLt {
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0; }

.LeftMenu_socialMediaIcon__CdEgq {
  width: 25px;
  height: 25px; }
  .LeftMenu_socialMediaIcon__CdEgq:hover {
    cursor: pointer; }

.LeftMenu_socialMediasList__pRGRs {
  margin-left: -5px;
  width: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 640px) {
    .LeftMenu_socialMediasList__pRGRs {
      margin-top: 20px; } }

.SectionRight_container__2SWAD {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative; }
  @media (max-width: 768px) {
    .SectionRight_container__2SWAD {
      display: none; } }

.SectionRight_menuList__27P1u {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.SectionRight_menu__3dfBd {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.SectionRight_menuTitle__2tQ7i {
  font-family: AvenirNext;
  font-size: 1.2em;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 0 0 16px 0; }

.SectionRight_menuItem__2iw7Q {
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 0 0 8px 0;
  text-decoration: none;
  -webkit-transition: 0.3s; }
  .SectionRight_menuItem__2iw7Q:hover {
    cursor: pointer;
    color: #96d8f3; }

.SectionRight_footer__1XoUN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  white-space: pre-line;
  position: absolute;
  bottom: 0;
  left: 0; }

.SectionRight_footerTxt__la3CX {
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 0; }

.IntroSection_container__25Lfd {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  border: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-size: cover;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.4) 82%, #ffffff); }

.IntroSection_contents__1Woru {
  max-width: 1060px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
  white-space: pre-line;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .IntroSection_contents__1Woru {
      padding: 80px 0 80px 0; } }

.IntroSection_title__6JlDp {
  text-shadow: 0 1px 7px #212529;
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0 0 60px 0; }

.IntroSection_intro__10jg7 {
  text-shadow: 0 1px 7px #212529;
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin: 0 0 40px 0; }

.IntroSection_btn__3NgVf {
  padding: 14px 40px;
  border-radius: 10px;
  border: solid 2px #96d8f3;
  background-color: #96d8f3;
  font-family: AvenirNext;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0;
  -webkit-transition: 0.3s;
  max-width: 80%; }
  @media (max-width: 768px) {
    .IntroSection_btn__3NgVf {
      font-size: 20px; } }
  .IntroSection_btn__3NgVf:hover {
    cursor: pointer; }

.exploreMore-warp {
  white-space: pre-wrap; }
  @media (max-width: 768px) {
    .exploreMore-warp .exploreMore-h1 {
      font-size: 30px !important; } }
  @media (max-width: 768px) {
    .exploreMore-warp .exploreMore-h2 {
      font-size: 20px !important; } }
  @media (max-width: 768px) {
    .exploreMore-warp {
      width: 80%; } }

@media (max-width: 768px) {
  .contentWrapper {
    width: 80%; } }

.ExtraIntro_container__tqorO {
  width: 100%;
  margin: 100px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.ExtraIntro_contents__2IZcM {
  min-width: 50%;
  max-width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  white-space: pre-line;
  margin-bottom: 50px; }
  .ExtraIntro_contents__2IZcM p {
    white-space: pre-wrap;
    width: 100%;
    font-family: Avenir;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    padding-bottom: 40px; }

.ExtraIntro_title__1H0MJ {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin-bottom: 40px; }

.ExtraIntro_images__3jG6e {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.ExtraIntro_image__1Rw7f {
  width: 33.33%;
  height: 309px;
  object-fit: cover; }

.HomePage_buttonWrapper__3PSmB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.HomePage_button__2K3sc {
  width: 415px;
  height: 61px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transition: 0.3s; }
  @media (max-width: 576px) {
    .HomePage_button__2K3sc {
      width: 300px; } }
  .HomePage_button__2K3sc:hover {
    cursor: pointer;
    background-color: #7dceef; }
  .HomePage_button__2K3sc p {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0; }

.SignUp_card__53EOB {
  width: 430px;
  border-radius: 10px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  padding: 0 0 30px 0;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .SignUp_card__53EOB {
      width: 90%; } }

.SignUp_btnClose__1RMhH {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px; }
  .SignUp_btnClose__1RMhH:hover {
    cursor: pointer; }

.SignUp_title__10lMp {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffa1b8;
  margin: 30px 0 25px 0; }
  @media (max-width: 768px) {
    .SignUp_title__10lMp {
      width: 80%; } }

.SignUp_nameInputContainer__1CY3r {
  width: 350px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (max-width: 768px) {
    .SignUp_nameInputContainer__1CY3r {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  @media (max-width: 768px) {
    .SignUp_nameInputContainer__1CY3r {
      width: 85%; } }

@media (max-width: 768px) {
  .SignUp_nameInputRowOne__SGmUe {
    margin-bottom: 10px; } }

.SignUp_nameInput__JQd2s {
  width: 170px;
  height: 53px;
  border-radius: 6px;
  border: solid 1px #a6a6b4; }
  @media (max-width: 768px) {
    .SignUp_nameInput__JQd2s {
      width: 100%; } }
  .SignUp_nameInput__JQd2s input {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    background-color: transparent;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none; }
    .SignUp_nameInput__JQd2s input::-webkit-input-placeholder {
      color: #c6c6d4; }
    .SignUp_nameInput__JQd2s input::-ms-input-placeholder {
      color: #c6c6d4; }
    .SignUp_nameInput__JQd2s input::placeholder {
      color: #c6c6d4; }

.SignUp_field__2uapb {
  width: 350px;
  height: 53px;
  border-radius: 6px;
  border: solid 1px #a6a6b4;
  margin-top: 10px; }
  @media (max-width: 768px) {
    .SignUp_field__2uapb {
      width: 85%; } }
  .SignUp_field__2uapb input, .SignUp_field__2uapb select {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    background-color: transparent;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none; }
    .SignUp_field__2uapb input::-webkit-input-placeholder, .SignUp_field__2uapb select::-webkit-input-placeholder {
      color: #c6c6d4; }
    .SignUp_field__2uapb input::-ms-input-placeholder, .SignUp_field__2uapb select::-ms-input-placeholder {
      color: #c6c6d4; }
    .SignUp_field__2uapb input::placeholder, .SignUp_field__2uapb select::placeholder {
      color: #c6c6d4; }

.SignUp_policy__vub0E {
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffa1b8;
  margin: 20px 0 25px 0; }
  .SignUp_policy__vub0E a {
    color: #ffa1b8;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
    .SignUp_policy__vub0E a:hover {
      cursor: pointer; }
  @media (max-width: 768px) {
    .SignUp_policy__vub0E {
      width: 90%; } }

.SignUp_btnSignUp__Yh16k {
  width: 192px;
  height: 46px;
  border-radius: 6px;
  background-image: linear-gradient(288deg, #c7efff, #e1d0ff 43%, #ffa8d4);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0; }
  .SignUp_btnSignUp__Yh16k:hover {
    cursor: pointer; }
  @media (max-width: 768px) {
    .SignUp_btnSignUp__Yh16k {
      width: 50%; } }

.SignUp_signUpTxt__3h0PK {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0; }

.SignUp_divider__2qFLe {
  width: 352px;
  height: 2px;
  opacity: 0.35;
  background-color: rgba(238, 163, 201, 0.5);
  margin: 25px 0 25px 0; }
  @media (max-width: 768px) {
    .SignUp_divider__2qFLe {
      width: 85%; } }

.SignUp_alreadyHaveAccount__1WADm {
  font-family: PingFangSC;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffabb9;
  margin: 0; }
  .SignUp_alreadyHaveAccount__1WADm span {
    color: #ffa1b8;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
    .SignUp_alreadyHaveAccount__1WADm span:hover {
      cursor: pointer; }

.SignUp_alert__l0LyJ {
  width: 350px;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fbede9;
  border-radius: 4px;
  margin-top: 10px; }
  @media (max-width: 768px) {
    .SignUp_alert__l0LyJ {
      width: 85%; } }
  .SignUp_alert__l0LyJ p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #e05425; }

.LogIn_card__xBSou {
  width: 430px;
  border-radius: 10px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  padding: 0 0 30px 0;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .LogIn_card__xBSou {
      width: 90%; } }

.LogIn_btnClose__Leyej {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px; }
  .LogIn_btnClose__Leyej:hover {
    cursor: pointer; }

.LogIn_title__c2Jsr {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffa1b8;
  margin: 30px 0 25px 0; }

.LogIn_field__2AcdG {
  width: 350px;
  height: 53px;
  border-radius: 6px;
  border: solid 1px #a6a6b4;
  margin-top: 10px; }
  @media (max-width: 768px) {
    .LogIn_field__2AcdG {
      width: 85%; } }
  .LogIn_field__2AcdG input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a6a6b4;
    background-color: transparent;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none; }

.LogIn_btnLogin__3A0q1 {
  width: 192px;
  height: 46px;
  border-radius: 6px;
  background-image: linear-gradient(288deg, #c7efff, #e1d0ff 43%, #ffa8d4);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 25px 0 25px 0; }
  .LogIn_btnLogin__3A0q1:hover {
    cursor: pointer; }
  .LogIn_btnLogin__3A0q1:focus {
    outline: none; }
  @media (max-width: 768px) {
    .LogIn_btnLogin__3A0q1 {
      width: 50%; } }

.LogIn_logInTxt__2tU6P {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0; }

.LogIn_dontHaveAccount__38l3u {
  font-family: PingFangSC;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffabb9;
  margin-top: 25px; }
  .LogIn_dontHaveAccount__38l3u span {
    color: #ffa1b8;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
    .LogIn_dontHaveAccount__38l3u span:hover {
      cursor: pointer; }

.LogIn_divider__3Eoby {
  width: 352px;
  height: 2px;
  opacity: 0.35;
  background-color: rgba(238, 163, 201, 0.5); }

.LogIn_alert__2vzTX {
  width: 350px;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fbede9;
  border-radius: 4px;
  margin-top: 10px; }
  @media (max-width: 768px) {
    .LogIn_alert__2vzTX {
      width: 85%; } }
  .LogIn_alert__2vzTX p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #e05425; }

.ForgetPasswordCard_card__1gGe8 {
  width: 430px;
  height: 324px;
  border-radius: 10px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  .ForgetPasswordCard_card__1gGe8 h1 {
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffa1b8;
    margin: 30px 0 25px 0; }
  .ForgetPasswordCard_card__1gGe8 p {
    width: 340px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffa6b9;
    margin: 0 0 0 25px; }

.ForgetPasswordCard_btnClose__z7PMC {
  width: 16px;
  height: 16px;
  background-color: rgba(255, 160, 163, 0.4);
  position: absolute;
  top: 20px;
  right: 20px; }
  .ForgetPasswordCard_btnClose__z7PMC:hover {
    cursor: pointer; }

.ForgetPasswordCard_field__3Mb1G {
  width: 350px;
  height: 53px;
  border-radius: 6px;
  border: solid 1px #a6a6b4;
  margin-top: 10px; }
  .ForgetPasswordCard_field__3Mb1G input {
    width: 100%;
    height: 55px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a6a6b4;
    background-color: transparent;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none; }

.ForgetPasswordCard_resetPswBtn__3nAJH {
  width: 294px;
  height: 46px;
  border-radius: 6px;
  background-image: linear-gradient(288deg, #c7efff, #e1d0ff 43%, #ffa8d4);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 25px 0 25px 0; }
  .ForgetPasswordCard_resetPswBtn__3nAJH:hover {
    cursor: pointer; }
  .ForgetPasswordCard_resetPswBtn__3nAJH p {
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0; }

.AlertCard_container__1-shk {
  width: 910px;
  min-height: 300px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  padding: 60px;
  box-sizing: border-box; }
  .AlertCard_container__1-shk h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1; }
  .AlertCard_container__1-shk p {
    font-family: Avenir;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e679b1;
    margin-bottom: 30px;
    padding: 0; }

.Application_container__3FzfA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.Application_title__PgNvQ {
  font-family: Avenir-Roman;
  font-size: 35px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  padding: 20px; }

.Application_buttonsWrapper__1SvkO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 200px; }
  @media (max-width: 768px) {
    .Application_buttonsWrapper__1SvkO {
      width: 100%;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-bottom: 50px; } }

.Application_alert__1H6Z1 {
  z-index: 2000;
  position: fixed;
  top: 5px;
  width: 700px;
  height: 35px;
  background-color: #e679b1;
  color: white;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 768px) {
    .Application_alert__1H6Z1 {
      width: 90%; } }
  .Application_alert__1H6Z1 button {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    background-color: transparent; }
    .Application_alert__1H6Z1 button:hover {
      text-decoration: none;
      cursor: pointer; }
    .Application_alert__1H6Z1 button span {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      color: white; }
      .Application_alert__1H6Z1 button span:hover {
        color: #52585f;
        text-decoration: none; }

.ProgressBar_container__1cDbA {
  width: 910px;
  height: 26px;
  border-radius: 13px;
  background-color: rgba(238, 163, 201, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media (max-width: 768px) {
    .ProgressBar_container__1cDbA {
      width: 90%; } }

.ProgressBar_progressTxt__3c770 {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0;
  margin: 0; }
  @media (max-width: 768px) {
    .ProgressBar_progressTxt__3c770 {
      font-size: 12px; } }

.Button_button__3WvLG {
  width: 200px;
  margin-right: 25px;
  height: 42px;
  border-radius: 5px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 768px) {
    .Button_button__3WvLG {
      width: 90%;
      margin-right: 0;
      margin-bottom: 10px; } }
  .Button_button__3WvLG:hover {
    cursor: pointer; }
  .Button_button__3WvLG p {
    font-family: Avenir;
    font-size: 15px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0; }

.FamilyHistory_container__16ZoH {
  width: 1109px;
  padding: 0 60px 70px 60px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .FamilyHistory_container__16ZoH {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .FamilyHistory_container__16ZoH h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }

.FamilyHistory_subTitle__2Gy8H {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 30px 0 23px 0; }

.FamilyHistory_row__13QsB {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box; }

.FamilyHistory_attrCell__12573 {
  width: 180px;
  padding: 0 40px 0 10px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  color: #e679b1; }

.FamilyHistory_valueCell__2uWtc {
  font-family: Avenir;
  font-size: 15px;
  text-align: justify;
  color: #fac8e2; }

.FamilyHistory_column__1hEPt {
  white-space: pre-line;
  margin: 0 18px 0 0;
  padding: 0;
  min-width: 93px;
  max-width: 158px;
  font-family: Avenir-Roman;
  font-size: 15px;
  text-align: center;
  color: #e679b1;
  position: relative; }
  .FamilyHistory_column__1hEPt input {
    width: 80px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 15px;
    text-align: center;
    color: #52585f;
    outline: none; }

.FamilyHistory_table__3sYyk {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768px) {
    .FamilyHistory_table__3sYyk {
      overflow: scroll; } }

.BasicInformation_container__HbZCv {
  width: 910px;
  padding: 0 60px 70px 60px;
  overflow: hidden;
  border-radius: 30px;
  box-sizing: border-box;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 768px) {
    .BasicInformation_container__HbZCv {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .BasicInformation_container__HbZCv h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }

.BasicInformation_row__1dSGR {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 20px; }
  @media (max-width: 768px) {
    .BasicInformation_row__1dSGR {
      -webkit-flex-direction: column;
              flex-direction: column; } }

@media (max-width: 768px) {
  .BasicInformation_cellMarginBottom__3V3xF {
    margin-bottom: 20px; } }

.BasicInformation_shortCell__1hQlw {
  width: 223px; }

.BasicInformation_mediumCell__3PChW {
  width: 365px; }

.BasicInformation_longCell__1MIlz {
  width: 100%; }

@media (max-width: 768px) {
  .BasicInformation_shortCell__1hQlw, .BasicInformation_mediumCell__3PChW {
    width: 100%; } }

.BasicInformation_cell__3khgW {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768px) {
    .BasicInformation_cell__3khgW {
      width: 100%; } }

.BasicInformation_fieldTitle__QR29Z {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0; }

.BasicInformation_highlightRequired__1C8uI {
  color: red; }

.BasicInformation_fieldContainer__INNt1, .BasicInformation_inputContainer__26_o-, .BasicInformation_dropdownContainer__AA-JM {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff; }

.BasicInformation_containerHighlight__2lpAF {
  border: solid 1px red; }

.BasicInformation_inputContainer__26_o-, .BasicInformation_dropdownContainer__AA-JM {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .BasicInformation_inputContainer__26_o- input, .BasicInformation_dropdownContainer__AA-JM input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    border: solid 1px transparent;
    outline: none;
    font-family: Avenir-Roman;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #52585f;
    padding: 10px;
    box-sizing: border-box; }
    .BasicInformation_inputContainer__26_o- input::-webkit-input-placeholder, .BasicInformation_dropdownContainer__AA-JM input::-webkit-input-placeholder {
      font-family: Avenir-Roman;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b5b5c0; }
    .BasicInformation_inputContainer__26_o- input::-ms-input-placeholder, .BasicInformation_dropdownContainer__AA-JM input::-ms-input-placeholder {
      font-family: Avenir-Roman;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b5b5c0; }
    .BasicInformation_inputContainer__26_o- input::placeholder, .BasicInformation_dropdownContainer__AA-JM input::placeholder {
      font-family: Avenir-Roman;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b5b5c0; }

.BasicInformation_dropdownContainer__AA-JM {
  position: relative; }
  .BasicInformation_dropdownContainer__AA-JM img {
    position: absolute;
    width: 16px;
    height: 11px;
    right: 20px; }
  .BasicInformation_dropdownContainer__AA-JM select {
    width: 100%;
    border-radius: 10px;
    background-color: transparent;
    border: solid 1px transparent;
    outline: none;
    -moz-appearance: window;
    -webkit-appearance: none;
    padding: 15px 10px;
    box-sizing: border-box;
    font-family: Avenir-Roman;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #52585f; }
    .BasicInformation_dropdownContainer__AA-JM select::-ms-expand {
      display: none;
      /* Hide dropdown in IE 10 & 11 */ }

.BasicInformation_checkbox__hvUVv {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  outline: none; }

.BasicInformation_checked__2K3-4 {
  background-color: #c3c8cc; }

.BasicInformation_textArea__1mVxg {
  width: 100%;
  height: 84px;
  border-radius: 10px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  outline: none;
  padding: 15px 10px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f; }

.BasicInformation_textAreaHighLight__1GryW {
  border: 1px solid red; }

.BasicInformation_othersContainer__1QxwK {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.BasicInformation_othersRow__oJsRu {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 20px 15px 0;
  box-sizing: border-box; }

.RadioButton_container__3T4ci {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .RadioButton_container__3T4ci:hover {
    cursor: pointer; }

.RadioButton_radioBtnOuter__2_85u {
  width: 18px;
  height: 18px;
  border-radius: 30px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.RadioButton_radioBtnInner__QQLEu {
  width: 12px;
  height: 12px;
  border-radius: 12px; }

.RadioButton_selected__3LKqB {
  background-color: #c3c8cc; }

.RadioButton_btnTxt__3qNwv {
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  margin: 0 0 0 10px; }

.HealthHistory_container__2Gcr8 {
  width: 910px;
  padding: 0 60px 70px 60px;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 768px) {
    .HealthHistory_container__2Gcr8 {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .HealthHistory_container__2Gcr8 h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 0 0; }

.HealthHistory_subTitle__FlnUt {
  width: 100%;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 30px 0 23px 0; }

.HealthHistory_row__3vU-w {
  width: 100%;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .HealthHistory_row__3vU-w {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start; } }

.HealthHistory_rowLeft__3fAwi {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 768px) {
    .HealthHistory_rowLeft__3fAwi {
      width: 100%; } }

.HealthHistory_attrCell__LdUie {
  width: 390px;
  padding: 0 46px 0 10px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.HealthHistory_highlightRequired__sWD1R {
  color: red; }

.HealthHistory_valueCell__H72Lm {
  font-family: Avenir;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #fac8e2; }

.HealthHistory_column__yDRnT {
  margin-left: 43px;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1; }

.HealthHistory_noteInput__3fOQU {
  width: 254px; }
  @media (max-width: 768px) {
    .HealthHistory_noteInput__3fOQU {
      width: 50%;
      margin-top: 10px;
      margin-left: 0; } }
  .HealthHistory_noteInput__3fOQU textarea {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: solid 1px #e6e9eb;
    background-color: #ffffff;
    outline: none;
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f; }

.ShowYourself_container__23g9J {
  width: 910px;
  padding: 0 60px 70px 60px;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  white-space: pre-line;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768px) {
    .ShowYourself_container__23g9J {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .ShowYourself_container__23g9J h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }

.ShowYourself_subtitle__bvPNj {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0; }

.ShowYourself_selectBox__AsoXm {
  width: 400px;
  height: 42px;
  border-radius: 5px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 0 0 15px;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .ShowYourself_selectBox__AsoXm {
      width: 100%; } }

.ShowYourself_fileName__v1BCm {
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  padding: 0;
  margin: 0; }

.ShowYourself_selectFileBtn__1pOk9 {
  width: 95px;
  height: 25px;
  border-radius: 5px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0; }
  .ShowYourself_selectFileBtn__1pOk9 p {
    font-family: Avenir;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #ffffff;
    padding: 0;
    margin: 0; }
  .ShowYourself_selectFileBtn__1pOk9:hover {
    cursor: pointer; }

.ShowYourself_selectedFiles__2DILA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px 0 10px 0; }

.ShowYourself_deleteIcon__27yWs {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin: 0 0 0 40px; }
  .ShowYourself_deleteIcon__27yWs:hover {
    cursor: pointer; }

.ShowYourself_instruction__iZtGV {
  width: 716px;
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1; }
  @media (max-width: 768px) {
    .ShowYourself_instruction__iZtGV {
      width: 100%; } }

.ShowYourself_inputFile__11OTb {
  display: none; }

.Completion_container__3r1P1 {
  width: 910px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 60px 70px 60px;
  box-sizing: border-box;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .Completion_container__3r1P1 {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .Completion_container__3r1P1 h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }

.Completion_signatureWrapper__14dKq {
  width: 790px;
  height: 196px;
  border-radius: 10px;
  border: solid 1.5px #e6e9eb; }
  @media (max-width: 768px) {
    .Completion_signatureWrapper__14dKq {
      width: 100%; } }

.Completion_subtitle__3dmfD {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
  padding: 0; }

.Completion_clear__3gfKl {
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: dodgerblue;
  text-align: right;
  text-decoration: underline;
  margin: 10px 0 31px 0;
  padding: 0; }
  .Completion_clear__3gfKl:hover {
    cursor: pointer; }

.Completion_cell__1A0Is {
  width: 365px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768px) {
    .Completion_cell__1A0Is {
      width: 100%;
      margin-bottom: 10px; } }

.Completion_inputs__3HM0H {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 768px) {
    .Completion_inputs__3HM0H {
      -webkit-flex-direction: column;
              flex-direction: column; } }

.Completion_fieldTitle__sQSwg {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0; }

.Completion_highlightRequired___xdh2 {
  color: red; }

.Success_container__JatM- {
  width: 910px;
  padding: 0 60px 70px 60px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .Success_container__JatM- {
      width: 90%;
      padding: 0 10px 40px 10px; } }
  .Success_container__JatM- h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }
  .Success_container__JatM- p {
    font-family: Avenir;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e679b1;
    margin: 30px 0 60px 0;
    padding: 0; }

.Success_reference__xgCAl {
  width: 320px;
  height: 63px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .Success_reference__xgCAl p {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0; }

.my-alert {
  z-index: 2000;
  position: fixed;
  top: 5px;
  width: 700px;
  height: 35px;
  background-color: red;
  color: white;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .my-alert.success {
    background-color: #99d8f1; }
  @media (max-width: 768px) {
    .my-alert {
      width: 90%; } }
  .my-alert button {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    background-color: transparent; }
    .my-alert button:hover {
      text-decoration: none;
      cursor: pointer; }
    .my-alert button span {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      color: white; }
      .my-alert button span:hover {
        color: #52585f;
        text-decoration: none; }

.EggDonationProcess_section__3jd5F {
  height: 100vh; }
  @media (max-width: 768px) {
    .EggDonationProcess_section__3jd5F {
      height: 70vh; } }

.EggDonationProcess_container__3QV8R {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.EggDonationProcess_contents__cNYpO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  white-space: pre-line;
  margin-top: 100px; }

.EggDonationProcess_title__s3TdS {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0; }

.EggDonationProcess_intro__3ja-6 {
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #52585f;
  margin: 0 0 50px 0; }

.EggDonationProcess_images__2CzVC {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 0 50px 0; }

.EggDonationProcess_image__2OOd7 {
  width: 33.33%;
  height: 309px;
  border-radius: 6px;
  background-color: #eeeeee; }

.EggDonationProcess_imgGene__2_quT {
  width: 1176px;
  height: 418px; }
  @media (max-width: 768px) {
    .EggDonationProcess_imgGene__2_quT {
      width: 100%;
      height: 200px; } }

.EggDonationProcess_processes___Dn2C {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .EggDonationProcess_processes___Dn2C div {
    max-width: 238px;
    margin: 0 20px 0 0;
    padding: 12px 30px 12px 30px;
    border-radius: 10px;
    font-family: Avenir-Roman;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f; }

.ChooseEggDonor_container__KjQ-m {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fdf8f8; }

.ChooseEggDonor_contents__1-4ef {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 100px 0;
  white-space: pre-line; }
  @media (max-width: 768px) {
    .ChooseEggDonor_contents__1-4ef a {
      display: none; } }

.ChooseEggDonor_title__3Y81B {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0; }

.ChooseEggDonor_intro__24jxK {
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #52585f;
  margin: 0 0 50px 0; }

.ChooseEggDonor_images__2rx-c {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 0 60px 0; }
  @media (max-width: 768px) {
    .ChooseEggDonor_images__2rx-c {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: center;
              justify-content: center; } }

.ChooseEggDonor_image__2X9ZC {
  width: 235px;
  height: 235px;
  object-fit: cover; }
  @media (max-width: 768px) {
    .ChooseEggDonor_image__2X9ZC {
      width: 100px;
      height: 100px;
      margin: 20px !important; } }

.ChooseEggDonor_btn__1gXiv {
  padding: 14px 40px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transition: 0.3s; }
  @media (max-width: 768px) {
    .ChooseEggDonor_btn__1gXiv {
      max-width: 80%;
      margin: auto; } }
  .ChooseEggDonor_btn__1gXiv:hover {
    cursor: pointer;
    background-color: #7dceef; }
  .ChooseEggDonor_btn__1gXiv p {
    font-family: AvenirNext;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0; }

.FAQs_container__p8HLR {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 100px 0; }

.FAQs_contents__yjr9w {
  width: 1060px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 100px 0 100px 0;
  white-space: pre-wrap; }

.FAQs_title__2Fclv {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 60px 0; }

.FAQs_processContainer__1xGzN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 0; }

.FAQs_processBubble__2wQ6g {
  width: 700px;
  height: 74px;
  border-radius: 46px;
  box-shadow: 0 2px 8px 0 rgba(238, 163, 201, 0.3);
  background-color: rgba(238, 163, 201, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .FAQs_processBubble__2wQ6g p {
    max-width: 521px;
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0; }

.FAQs_iconNext__2GwMJ {
  width: 42px;
  height: 40px;
  margin: 10px 0 10px 0;
  box-shadow: 0 2px 4px 0 rgba(195, 232, 247, 0.3);
  background-color: #c3e8f7; }

.FAQs_questionWrapper__Km_g7 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  white-space: pre-wrap;
  padding: 20px 0 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e9eb; }
  .FAQs_questionWrapper__Km_g7 p {
    -webkit-margin-before: 1em;
            margin-block-start: 1em; }

.FAQs_question__26IoZ {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  white-space: pre-wrap; }
  .FAQs_question__26IoZ:hover {
    cursor: pointer; }
  .FAQs_question__26IoZ p {
    font-family: Avenir;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e679b1;
    padding: 0;
    margin: 0; }
  .FAQs_question__26IoZ img {
    width: 12px;
    height: 18px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: 0.3s; }

.FAQs_answer__1fVvI {
  font-family: Avenir;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #52585f;
  margin-bottom: 0px; }

.fqa-container {
  max-width: 1060px;
  margin: 0 auto;
  margin-bottom: 60px; }
  .fqa-container .fqa-wrap {
    padding: 0 20px; }
    @media (max-width: 768px) {
      .fqa-container .fqa-wrap h1 {
        margin: 60px 0; } }
    @media (max-width: 768px) {
      .fqa-container .fqa-wrap > div p {
        font-size: 16px !important; } }

.Process_container__-tEo5 {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fdf8f8;
  padding: 100px 0; }

.Process_contents__3MQDc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 100px 0;
  white-space: pre-line; }

.Process_title__peVHL {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0; }

.Process_processContainer__nHY9h {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 0; }

.Process_processBubble__1kS4D {
  width: 600px;
  height: 74px;
  border-radius: 46px;
  box-shadow: 0 2px 8px 0 rgba(238, 163, 201, 0.3);
  background-color: rgba(238, 163, 201, 0.7);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .Process_processBubble__1kS4D p {
    max-width: 521px;
    font-family: Avenir;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0; }

.Process_iconNext__3O_ix {
  width: 35px;
  height: 40px;
  margin: 10px 0 10px 0; }

@media (max-width: 768px) {
  .egg-process-container .egg-process-item {
    width: 300px !important; }
    .egg-process-container .egg-process-item .description-step {
      font-size: 14px; } }

.WeAreLeader_container__dBB3U {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .WeAreLeader_container__dBB3U {
      margin: auto; } }

.WeAreLeader_contents__27ml4 {
  max-width: 1060px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 100px 0 0 0;
  white-space: pre-line; }

.WeAreLeader_title__2FEC2 {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0; }

.WeAreLeader_intro__1La3n {
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  margin: 0 0 50px 0; }
  @media (max-width: 768px) {
    .WeAreLeader_intro__1La3n {
      width: 90%; } }

.WeAreLeader_imgGene__32BtB {
  width: 1176px;
  height: 418px; }
  @media (max-width: 768px) {
    .WeAreLeader_imgGene__32BtB {
      width: 100%;
      height: 200px; } }

.WeAreLeader_images__34N-K {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 50px 0; }

.WeAreLeader_image__39xD_ {
  width: 33.33%;
  height: 309px;
  background-color: #eeeeee;
  object-fit: cover; }

.FertilityCenter_container__2kPUP {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.FertilityCenter_contents__8Ef62 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 50px 0 100px 0;
  white-space: pre-line; }

.FertilityCenter_title__2oJRO {
  font-family: Avenir-Roman;
  font-size: 42px;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0; }
  @media (max-width: 768px) {
    .FertilityCenter_title__2oJRO {
      font-size: 28px; } }

.FertilityCenter_introWrapper__2W4xO {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 130px 0 130px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  opacity: 0;
  margin: 0;
  transition: opacity 0.5s ease; }
  @media (max-width: 768px) {
    .FertilityCenter_introWrapper__2W4xO {
      padding: 0 20px 0 20px; } }
  @media (max-width: 768px) {
    .FertilityCenter_introWrapper__2W4xO {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; } }
  .FertilityCenter_introWrapper__2W4xO img {
    width: 590px;
    height: 379px;
    border-radius: 6px;
    object-fit: scale-down;
    margin-right: 60px; }
    @media (max-width: 768px) {
      .FertilityCenter_introWrapper__2W4xO img {
        width: 100%;
        height: 190px;
        margin-right: 0;
        margin-bottom: 10px; } }

.FertilityCenter_details__171L0 {
  width: 530px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: auto; }
  @media (max-width: 768px) {
    .FertilityCenter_details__171L0 {
      width: 100%; } }
  .FertilityCenter_details__171L0 h1 {
    font-family: Avenir;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #e679b1;
    margin: 0 0 25px 0; }

.FertilityCenter_detailsBody__2vkNW {
  max-height: 180px;
  overflow: hidden;
  font-family: Avenir;
  font-size: 22px;
  text-align: justify;
  color: #52585f; }
  @media (max-width: 768px) {
    .FertilityCenter_detailsBody__2vkNW {
      max-height: -webkit-fit-content;
      max-height: -moz-fit-content;
      max-height: fit-content; } }

.FertilityCenter_swiperContainer__2xaDp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin: 60px 0 0 0; }
  @media (max-width: 768px) {
    .FertilityCenter_swiperContainer__2xaDp {
      width: 375px; } }

.FertilityCenter_swiperContent__1cm-0 {
  margin: 10px auto;
  display: inherit; }

.FertilityCenter_indicatorLeft__2TdzO, .FertilityCenter_indicatorRight__1ereS {
  width: 15px;
  height: 32px;
  margin: auto 20px; }
  .FertilityCenter_indicatorLeft__2TdzO:hover, .FertilityCenter_indicatorRight__1ereS:hover {
    cursor: pointer; }

.FertilityCenter_indicatorRight__1ereS {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.FertilityCenter_card__3KlVk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  @media (max-width: 768px) {
    .FertilityCenter_card__3KlVk {
      width: 100%; } }
  .FertilityCenter_card__3KlVk:hover {
    cursor: pointer; }
  .FertilityCenter_card__3KlVk img {
    width: 360px;
    height: 200px;
    object-fit: scale-down;
    border-radius: 6px;
    position: relative;
    margin: 0; }
    @media (max-width: 768px) {
      .FertilityCenter_card__3KlVk img {
        width: 260px; } }

.FertilityCenter_carouselWrapper__1JivU {
  width: 1080px;
  overflow: hidden; }
  @media (max-width: 768px) {
    .FertilityCenter_carouselWrapper__1JivU {
      width: 260px; } }

.FertilityCenter_carousel__n4NF8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  box-sizing: border-box;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease; }

.FertilityCenter_btn__3juGr {
  padding: 5px 28px;
  border-radius: 5px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transition: 0.3s; }
  @media (max-width: 768px) {
    .FertilityCenter_btn__3juGr {
      padding: 10px 28px; } }
  .FertilityCenter_btn__3juGr:hover {
    cursor: pointer;
    background-color: #7dceef; }
  .FertilityCenter_btn__3juGr p {
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0; }

.SuccessStories_container__3Zldi {
  width: 100%;
  position: relative; }

.SuccessStories_contents__5ZFDa {
  width: 100%;
  min-height: 100vh;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.SuccessStories_pageTitle__hqEds {
  font-family: Avenir-Roman;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 60px 0 60px 0; }

.SuccessStories_storiesBoard__1n0zo {
  width: 1089px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 768px) {
    .SuccessStories_storiesBoard__1n0zo {
      width: auto;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; } }

.SuccessStories_storyCard__U_jeL {
  width: 333px;
  height: 333px;
  border-radius: 10px;
  background-size: cover;
  background-color: rgba(154, 154, 170, 0.9);
  margin: 20px 15px 20px 15px;
  position: relative;
  transition: box-shadow 0.3s ease-in-out; }
  .SuccessStories_storyCard__U_jeL:hover {
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32); }
  .SuccessStories_storyCard__U_jeL div {
    width: 100%;
    height: 65px;
    opacity: 0.9;
    background-color: #9a9aaa;
    position: absolute;
    bottom: 0;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    border-radius: 0 0 10px 10px; }
  .SuccessStories_storyCard__U_jeL h1 {
    max-width: 100%;
    font-family: Avenir;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 19px 0 0 0; }
  .SuccessStories_storyCard__U_jeL p {
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 5px 0 0 0; }

.ProfilesList_container__EGchP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.ProfilesList_list__FOmHI {
  width: 100%;
  overflow: auto;
  display: -webkit-flex;
  display: flex;
  padding: 0 36px;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column; }

.ProfilesList_title__zAYrq {
  margin: 100px 0;
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1; }

.DonorsListCard_container__1w9IF {
  width: 1060px;
  height: 140px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .DonorsListCard_container__1w9IF:hover {
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease-in-out; }
  .DonorsListCard_container__1w9IF img {
    width: 151px;
    height: 100%;
    border-radius: 15px 0 0 15px;
    background-color: #666666;
    object-fit: cover; }

.DonorsListCard_infoArea__2TNcB {
  width: 909px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }

.DonorsListCard_row__3EjaA {
  width: 100%;
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 101px;
  box-sizing: border-box; }
  .DonorsListCard_row__3EjaA p {
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0;
    color: #e679b1; }

.DonorsListCard_col__2k0TF {
  min-width: 334px;
  max-width: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.PaginationBar_container__3H10S {
  padding: 5px 0 5px 0;
  margin: 0 0 70px 0;
  list-style-type: none;
  box-sizing: border-box; }
  .PaginationBar_container__3H10S li {
    font-size: 20px;
    font-weight: 600;
    display: inline;
    color: #99d8f1;
    margin: 0 25px 0 25px; }
    .PaginationBar_container__3H10S li:hover {
      cursor: pointer; }
  .PaginationBar_container__3H10S a {
    outline: none; }

.PaginationBar_next__1R-BH {
  font-size: 100px;
  margin: 0; }

.PaginationBar_active__3jTGm {
  font-size: 24px;
  color: pink; }

.FilterBar_container__2zuay {
  width: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 0 auto 50px auto; }

.FilterBar_category__1Np-K {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .FilterBar_category__1Np-K p {
    padding: 3px 10px; }
  .FilterBar_category__1Np-K span {
    padding: 3px 10px; }

.FilterBar_selected__3WKoQ {
  border-radius: 4px;
  background-color: rgba(238, 163, 201, 0.86); }

.FilterBar_filterOptions__2fi-3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .FilterBar_filterOptions__2fi-3 p:hover {
    cursor: pointer; }

.spin-loading .loading-icon {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  z-index: 200; }
  .spin-loading .loading-icon::after {
    content: 'Loading...';
    color: #1890ff;
    position: relative;
    left: -10px;
    top: 4px; }

.ForSurrogate_container__2q4F7 {
  width: 100%;
  position: relative; }

.ForSurrogate_buttonWrapper__2bacW {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #fdf8f8; }

.ForSurrogate_button__2RtI_ {
  width: 415px;
  height: 61px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 50px;
  -webkit-transition: 0.3s; }
  @media (max-width: 576px) {
    .ForSurrogate_button__2RtI_ {
      width: 300px; } }
  .ForSurrogate_button__2RtI_:hover {
    cursor: pointer;
    background-color: #7dceef; }
  .ForSurrogate_button__2RtI_ p {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0; }

.BecomeSurrogateMother_container__1g7pb {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  white-space: pre-line;
  background-color: #fdf8f8; }
  .BecomeSurrogateMother_container__1g7pb h1 {
    font-family: Avenir-Roman;
    font-size: 42px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0; }
  .BecomeSurrogateMother_container__1g7pb p {
    font-family: AvenirNext;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    margin-top: 0px; }
    @media (max-width: 576px) {
      .BecomeSurrogateMother_container__1g7pb p {
        padding: 0 20px; } }
  .BecomeSurrogateMother_container__1g7pb a {
    margin-bottom: 100px; }

.BecomeSurrogateMother_button__2UWOt {
  width: 415px;
  height: 61px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 60px 0 0 0;
  -webkit-transition: 0.3s; }
  @media (max-width: 576px) {
    .BecomeSurrogateMother_button__2UWOt {
      width: 300px; } }
  .BecomeSurrogateMother_button__2UWOt:hover {
    cursor: pointer;
    background-color: #7dceef; }
  .BecomeSurrogateMother_button__2UWOt p {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0; }

.container {
  width: 100%;
  position: relative; }

.BecomeEggDonor_container__1Dsse {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  white-space: pre-line;
  background-color: #fdf8f8; }
  .BecomeEggDonor_container__1Dsse h1 {
    font-family: Avenir-Roman;
    font-size: 42px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0; }
  .BecomeEggDonor_container__1Dsse p {
    font-family: AvenirNext;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    margin-top: 0px; }
    @media (max-width: 576px) {
      .BecomeEggDonor_container__1Dsse p {
        padding: 0 20px; } }
  .BecomeEggDonor_container__1Dsse a {
    margin-bottom: 100px; }

.BecomeEggDonor_button__1bdVp {
  padding: 14px 40px;
  height: 61px;
  border-radius: 10px;
  background-color: #99d8f1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 60px 0 0 0;
  -webkit-transition: 0.3s; }
  @media (max-width: 576px) {
    .BecomeEggDonor_button__1bdVp {
      width: 300px; } }
  .BecomeEggDonor_button__1bdVp:hover {
    cursor: pointer;
    background-color: #7dceef; }
  .BecomeEggDonor_button__1bdVp p {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0; }

.Post_container__21jih {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Post_postHeader__1hJjs {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: center;
  background-image: linear-gradient(to left, rgba(248, 216, 206, 0.86), rgba(238, 163, 201, 0.86));
  box-sizing: border-box; }
  .Post_postHeader__1hJjs .Post_headerContent__3zFaC {
    max-width: 1140px;
    margin: 0 auto; }
  .Post_postHeader__1hJjs h1 {
    font-family: Avenir;
    font-size: 42px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 120px 0 60px 0; }
  .Post_postHeader__1hJjs p {
    font-family: Avenir;
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }

.Post_contents__VA6Dr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 50px auto;
  max-width: 1140px;
  width: 90%; }
  .Post_contents__VA6Dr p {
    color: #52585f;
    font-size: 20px; }
  .Post_contents__VA6Dr img {
    margin: auto;
    display: block;
    max-width: 40%;
    height: auto; }

.Post_media-wrap__3ptQs {
  text-align: center; }

.Post_subTitle__XAXtQ {
  text-transform: uppercase;
  padding: 20px 0;
  margin-bottom: 0px; }

.Post_name__3DJEj {
  color: #999;
  font-style: italic;
  margin-bottom: 20px;
  font-size: 14px; }
  .Post_name__3DJEj span {
    color: #000; }

.SurrogacyProcess_container__z1ZzY {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.SurrogacyProcess_contents__3psa6 {
  max-width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 100px 0 0 0;
  white-space: pre-line; }

.SurrogacyProcess_title__B6Jow {
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0; }

.SurrogacyProcess_intro__2dtRZ {
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  margin: 0 0 50px 0; }

.SurrogacyProcess_images__Ngxqj {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 0 50px 0; }

.SurrogacyProcess_image__3DtMy {
  width: 33.33%;
  height: 309px;
  background-color: #eeeeee;
  object-fit: cover; }

.ChildrenStatusTable_container__2Rwzs {
  width: 1109px;
  padding: 0 60px 70px 60px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .ChildrenStatusTable_container__2Rwzs {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .ChildrenStatusTable_container__2Rwzs h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }

.ChildrenStatusTable_subTitle__3SryD {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 30px 0 23px 0; }

.ChildrenStatusTable_row__1hNEj {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box; }

.ChildrenStatusTable_attrCell__2LVP- {
  width: 180px;
  padding: 0 40px 0 10px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  color: #e679b1; }

.ChildrenStatusTable_valueCell__3V7Cc {
  font-family: Avenir;
  font-size: 15px;
  text-align: justify;
  color: #fac8e2; }

.ChildrenStatusTable_column__1I5ul {
  white-space: pre-line;
  margin: 0 30px 0 0;
  padding: 0;
  min-width: 93px;
  max-width: 120px;
  font-family: Avenir-Roman;
  font-size: 15px;
  text-align: center;
  color: #e679b1;
  position: relative; }
  .ChildrenStatusTable_column__1I5ul input {
    width: 110px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 15px;
    text-align: center;
    color: #52585f;
    outline: none; }

.ChildrenStatusTable_table__2sbAz {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768px) {
    .ChildrenStatusTable_table__2sbAz {
      overflow: scroll; } }

.JobTable_container__5Q28t {
  width: 1109px;
  padding: 0 60px 70px 60px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .JobTable_container__5Q28t {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .JobTable_container__5Q28t h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }

.JobTable_subTitle__3QvIU {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 30px 0 23px 0; }

.JobTable_row__zClle {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box; }

.JobTable_attrCell__1WEYf {
  width: 180px;
  padding: 0 40px 0 10px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  color: #e679b1; }

.JobTable_valueCell__3ERgq {
  font-family: Avenir;
  font-size: 15px;
  text-align: justify;
  color: #fac8e2; }

.JobTable_column__1fH8I {
  white-space: pre-line;
  margin: 0 60px 0 0;
  padding: 0;
  min-width: 93px;
  max-width: 158px;
  font-family: Avenir-Roman;
  font-size: 15px;
  text-align: center;
  color: #e679b1;
  position: relative; }
  .JobTable_column__1fH8I input {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 15px;
    text-align: center;
    color: #52585f;
    outline: none; }

.JobTable_table__3O_kG {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768px) {
    .JobTable_table__3O_kG {
      overflow: scroll; } }

.Completion_container__1KUw6 {
  width: 910px;
  padding: 0 60px 70px 60px;
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .Completion_container__1KUw6 {
      width: 90%;
      padding: 0 10px 70px 10px; } }
  .Completion_container__1KUw6 h1 {
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0; }
  .Completion_container__1KUw6 span {
    color: #e679b1; }
  .Completion_container__1KUw6 ol li {
    counter-increment: list;
    list-style-type: none;
    position: relative; }
  .Completion_container__1KUw6 ol li:before {
    color: #e679b1;
    font-size: 12px;
    content: counter(list) ".";
    top: 5.5px;
    left: -32px;
    position: absolute;
    text-align: right;
    width: 26px; }

.Completion_subtitle__2CKxy {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
  padding: 0; }

.Completion_clear__4eyzz {
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: dodgerblue;
  text-align: right;
  text-decoration: underline;
  margin: 10px 0 31px 0;
  padding: 0; }
  .Completion_clear__4eyzz:hover {
    cursor: pointer; }

.Completion_cell___q0Wv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Completion_fieldTitle__212bd {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0; }

.Completion_signaturePadWrapper__9gfbw {
  width: 790px;
  height: 196px;
  border-radius: 10px;
  border: 1.5px solid #e6e9eb;
  margin-top: 20px; }
  @media (max-width: 768px) {
    .Completion_signaturePadWrapper__9gfbw {
      width: 100%; } }

.DonorProfile_container__1Gofs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.DonorProfile_contents__1XiLr {
  width: 100%;
  overflow: auto;
  display: -webkit-flex;
  display: flex;
  padding: 0 36px;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column; }

.DonorProfile_title__27YUT {
  margin: 99px 0 101px 0;
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1; }

.PersonalInfo_container__3ThOg {
  width: 1060px;
  border-radius: 30px;
  margin: 100px auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .PersonalInfo_container__3ThOg h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.PersonalInfo_row__zZwaG {
  width: 100%;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex; }

.PersonalInfo_attrCell__1m2je {
  width: 365px;
  padding: 0 0 0 60px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1;
  margin-right: 100px; }

.PersonalInfo_valueCell__13Jnw {
  width: calc(100% - 525px);
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.MedicalInfo_container__3sDcm {
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .MedicalInfo_container__3sDcm h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.MedicalInfo_row__225S- {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.MedicalInfo_attrCell__uCtI2 {
  width: 400px;
  padding: 0 0 0 60px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.MedicalInfo_valueCell__3s7Io {
  font-family: Avenir;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #fac8e2; }

.MedicalInfo_column__1lfp8 {
  max-width: 360px;
  min-width: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  white-space: normal;
  white-space: initial;
  margin-left: 45px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  word-break: break-word; }

.FamilyInfo_container__1b74m {
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .FamilyInfo_container__1b74m h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.FamilyInfo_row__2Z-6r {
  width: 100%;
  padding: 15px 0 30px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.FamilyInfo_attrCell__rVepH {
  width: 230px;
  padding: 0 40px 0 60px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.FamilyInfo_valueCell__3I0C8 {
  font-family: Avenir;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #fac8e2; }

.FamilyInfo_column__2yIur {
  white-space: pre-line;
  margin: 0 40px 0 0;
  padding: 0;
  min-width: 80px;
  max-width: 158px;
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  position: relative; }

.FamilyInfo_valueWrapper__3awtk {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; }
  .FamilyInfo_valueWrapper__3awtk textarea {
    width: 100px;
    height: 60px;
    border-radius: 10px;
    border: solid 1.5px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #52585f;
    outline: none;
    padding: 0 5px 0 5px;
    box-sizing: border-box; }

.Carousel_container__RGIme {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  .Carousel_container__RGIme img {
    width: 15px;
    height: 32px; }
    .Carousel_container__RGIme img:hover {
      cursor: pointer; }

.Carousel_wrapper__1ZpB9 {
  width: 1200px;
  overflow: hidden; }

.Carousel_carousel__1RJmE {
  display: -webkit-flex;
  display: flex;
  width: 1200px;
  height: 400px;
  -webkit-flex-direction: row;
          flex-direction: row;
  box-sizing: border-box;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease; }
  .Carousel_carousel__1RJmE img {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 300px;
    height: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    object-fit: contain; }

.SurrogateMotherProfile_container__2Ljj5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.SurrogateMotherProfile_contents__2A7kf {
  width: 100%;
  overflow: auto;
  display: -webkit-flex;
  display: flex;
  padding: 0 36px;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column; }

.SurrogateMotherProfile_title__2UPfM {
  margin: 99px 0 101px 0;
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1; }

.PersonalInfo_container__q22oT {
  width: 1060px;
  border-radius: 30px;
  margin: 100px auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .PersonalInfo_container__q22oT h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.PersonalInfo_row__3-_bw {
  width: 100%;
  padding: 15px 0 15px 0;
  display: -webkit-flex;
  display: flex; }

.PersonalInfo_attrCell__1cRP3 {
  width: 365px;
  padding: 0 0 0 60px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.PersonalInfo_valueCell__2l_bI {
  width: calc(100% - 525px);
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.Carousel_container__3qBqP {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  .Carousel_container__3qBqP img {
    width: 15px;
    height: 32px; }
    .Carousel_container__3qBqP img:hover {
      cursor: pointer; }

.Carousel_wrapper__1zfy2 {
  width: 1200px;
  overflow: hidden; }

.Carousel_carousel__Jl4cG {
  display: -webkit-flex;
  display: flex;
  width: 1200px;
  height: 400px;
  -webkit-flex-direction: row;
          flex-direction: row;
  box-sizing: border-box;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease; }
  .Carousel_carousel__Jl4cG img {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 300px;
    height: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    object-fit: contain; }

.BirthRecords_container__KcVys {
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .BirthRecords_container__KcVys h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.BirthRecords_row__1pwr6 {
  width: 100%;
  padding: 25px 0 40px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.BirthRecords_attrCell__3GE8f {
  width: 230px;
  padding: 0 40px 0 60px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.BirthRecords_column__1stS0 {
  white-space: pre-line;
  margin: 0 18px 0 0;
  padding: 0;
  min-width: 140px;
  max-width: 160px;
  font-family: Avenir-Roman;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  position: relative; }
  .BirthRecords_column__1stS0 textarea {
    width: 140px;
    height: 60px;
    border-radius: 10px;
    border: solid 1.5px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #52585f;
    outline: none;
    padding: 5px 5px 0 5px;
    box-sizing: border-box; }

.DiseasesHistory_container__JepJN {
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .DiseasesHistory_container__JepJN h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.DiseasesHistory_cell__3x7J_ {
  position: relative; }

.DiseasesHistory_fieldTitle__hhgYQ {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0; }

.DiseasesHistory_checkboxWrapper__1fCLF {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.DiseasesHistory_othersContainer__ccY9t {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.DiseasesHistory_othersRow__1dbZG {
  width: 100%;
  padding: 15px 60px 15px 60px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.DiseasesHistory_answersWrapper__hktQP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.MedicalInfo_container__3zVGE {
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .MedicalInfo_container__3zVGE h1 {
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0; }

.MedicalInfo_row__2prSn {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 15px 60px 15px 60px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.MedicalInfo_attrCell__DaOno {
  width: 400px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1; }

.MedicalInfo_valuesWrapper__3RX9o {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.MedicalInfo_column__2WVx0 {
  max-width: 360px;
  min-width: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  white-space: normal;
  white-space: initial;
  margin-left: 45px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  word-break: break-word; }

