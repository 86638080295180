@import '../../components/commonStyle/common.scss';
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 35px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  padding: 20px;
}
.buttonsWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 200px;
  @include media-breakpoint-down(md) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 50px;
  }
}
.alert{
  z-index: 2000;
  position: fixed;
  top: 5px;
  width: 700px;
  @include media-breakpoint-down(md) {
    width: 90%;
  }
  height: 35px;
  background-color: #e679b1;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    span{
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      color: white;
      &:hover {
        color: #52585f;
        text-decoration: none;
      }
    }
  }
}
