@import '../../components/commonStyle/common.scss';

.fqa-container{
  // @include media-breakpoint-down(md) {
  //   padding: 0 !important;
  // }
  max-width: 1060px;
  margin: 0 auto;
  margin-bottom: 60px;
  .fqa-wrap{
    padding: 0 20px;
    h1{
      @include media-breakpoint-down(md) {
        margin: 60px 0;
      }
    }
    & > div{
      p{
        @include media-breakpoint-down(md) {
          font-size: 16px !important;
        }
      }
    }
  }
}
