@import '../../../commonStyle/common.scss';

$whiteBackColor: #2A2A2A;
.nav-container{
  .logo{
    width: 165px;
    height: 45px;
    @include media-breakpoint-down(sm) {
      width: 120px;
      height: 32.73px;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .logo-black{
    filter: invert(0) hue-rotate(180deg) contrast(2);
    @include media-breakpoint-down(md) {
      filter: invert(1) hue-rotate(180deg) contrast(1);
    }
  }
  .nav-link{
    color: $fontColor;
  }
  .navbar-brand{
    color: $fontColor;
  }
  .navbar-toggler{
    border: 1px solid $fontColor;
    color: $fontColor;
  }
  .btn {
    font-weight: 600;
  }
  .navbar-nav{
    & > li{
      margin-left: 1rem;
    }
    .dropdown-menu{
      $menuWidth: 14rem;
      $menuHeight: 28rem;
      border-radius: 0;
      // border: 2px solid #000;
      border: 0;
      @mixin containerStyle{
        width: $menuWidth;
        height: $menuHeight;
        background-color: rgba(0,0,0,0.6);
      }
      @mixin hoverStyle{
        height: 3.125rem;
        padding: 0 2.5rem;
        color: #999;
        font-size: 1.125rem;
        line-height: 3.125rem;
        cursor: pointer;
        &:hover{
          background-color: #e61b47;
          color: #fff;
        }
      }
      @include containerStyle;
      .dropdown-item{
        @include hoverStyle;
      }
      .secondary-menu{
        padding: 0.5rem 0;
        position: absolute;
        top: 0;
        left: $menuWidth;
        margin-left: -1px;
        // border: 2px solid #000;
        border: 0;
        @include containerStyle;
        .secondary-item{
          padding: 0.25rem 1.5rem;
          @include hoverStyle;
        }
      }
      @include media-breakpoint-down(md) {
        .secondary-menu{
          display: none
        }
      }
    }
  }
}

.white-back{
  @keyframes arrows {
    from {transform: translateY(20px);}
    to {transform: translateY(0);}
  }
  animation: arrows 0.6s ease 0s 1;
  background: rgba(255,255,255,0.85);
  @include media-breakpoint-down(md) {
    background-image: linear-gradient(to left, rgba(248, 216, 206, 0.86), rgba(238, 163, 201, 0.86));
  }
  .nav-link{
    color: $whiteBackColor;
    @include media-breakpoint-down(md) {
      color: #fff;
    }
  }
  .navbar-brand{
    color: $whiteBackColor;
    @include media-breakpoint-down(md) {
      color: #fff;
    }
  }
  .navbar-toggler{
    border: 1px solid $fontColor;
    color: $whiteBackColor;
    @include media-breakpoint-down(md) {
      color: #fff;
    }
  }
  .navbar-nav{
    .nav-item{
      margin-left: 1rem;
    }
  }
}

.dropdown-toggle {
  &::after {
    position: relative;
    vertical-align: middle !important;
  }
}
