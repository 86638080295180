@import '../../../../components/commonStyle/common.scss';

.container{
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.menuList{
  display: flex;
  flex-direction: row;
}
.menu{
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.menuTitle{
  font-family: AvenirNext;
  font-size: 1.2em;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 0 0 16px 0;
}
.menuItem{
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 0 0 8px 0;
  text-decoration: none;
  -webkit-transition: 0.3s;
  &:hover{
    cursor: pointer;
    color: #96d8f3;
  }
}
.footer{
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footerTxt{
  font-family: AvenirNext;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #fefffe;
  margin: 0;
}
