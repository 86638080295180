@import '../../../../../components/commonStyle/common.scss';
.container{
  width: 910px;
  padding: 0 60px 70px 60px;
  @include media-breakpoint-down(md) {
    width: 90%;
    padding: 0 10px 70px 10px;
  }
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  h1{
    
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0;
  }
  span {
    color: #e679b1;
  }
  ol li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
  }
  ol li:before {
    color: #e679b1;
    font-size: 12px;
    content: counter(list) ".";
    top: 5.5px;
    left:-32px;
    position: absolute;
    text-align: right;
    width: 26px;
  }
}
.subtitle{
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
  padding: 0;
}
.clear{
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: dodgerblue;
  text-align: right;
  text-decoration: underline;
  margin: 10px 0 31px 0;
  padding: 0;
  &:hover{
    cursor: pointer;
  }
}
.cell{
  display: flex;
  flex-direction: column;
}
.fieldTitle{
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
}
.signaturePadWrapper{
  width: 790px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  height: 196px;
  border-radius: 10px;
  border: 1.5px solid #e6e9eb;
  margin-top: 20px;
}
