$fontColor: #FFF;
$colPadding: 0.75rem;

@mixin media-breakpoint-up($size) {
  @if $size == sm {
    @media (min-width: 576px) {
      @content
    }
  };
  @if $size == md {
    @media (min-width: 768px) {
      @content
    }
  };
  @if $size == lg {
    @media (min-width: 992px) {
      @content
    }
  };
  @if $size == xl {
    @media (min-width: 1140px) {
      @content
    }
  }
};

@mixin media-breakpoint-down($size) {
  @if $size == sm {
    @media (max-width: 576px) {
      @content
    }
  };
  @if $size == md {
    @media (max-width: 768px) {
      @content
    }
  };
  @if $size == lg {
    @media (max-width: 992px) {
      @content
    }
  };
  @if $size == xl {
    @media (max-width: 1140px) {
      @content
    }
  }
}
