@import '../../../../../components/commonStyle/common.scss';
.container{
  width: 1109px;
  padding: 0 60px 70px 60px;
  @include media-breakpoint-down(md) {
    width: 90%;
    padding: 0 10px 70px 10px;
  }
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  h1{
    
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0;
  }
}
.subTitle{
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 30px 0 23px 0;
}
.row{
  width: fit-content;
  min-width: 100%;
  padding: 15px 0 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
.attrCell{
  width: 180px;
  padding: 0 40px 0 10px;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  color: #e679b1;
}
.valueCell{
  font-family: Avenir;
  font-size: 15px;
  text-align: justify;
  color: #fac8e2;
}
.column{
  white-space: pre-line;
  margin: 0 30px 0 0;
  padding: 0;
  min-width: 93px;
  max-width: 120px;
  font-family: Avenir-Roman;
  font-size: 15px;
  text-align: center;
  color: #e679b1;
  position: relative;
  input{
    width: 110px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e6e9eb;
    background-color: #ffffff;
    font-family: Avenir-Roman;
    font-size: 15px;
    text-align: center;
    color: #52585f;
    outline: none;
  }
}
.table{
  width: 100%;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    overflow: scroll;
  }
}
