.container{
  width: 1060px;
  border-radius: 30px;
  margin: 0 auto 60px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    
    font-family: Avenir;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 18px 0;
  }
}
.row{
  width: 100%;
  height: fit-content;
  padding: 15px 60px 15px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.attrCell{
  width: 400px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #e679b1;
}
.valuesWrapper{
  display: flex;
  flex-direction: row;
}
.column{
  max-width: 360px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  white-space: initial;
  margin-left: 45px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  word-break: break-word;
}
