.container{
  width: 100%;
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contents{
  min-width: 50%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  margin-bottom: 50px;
  p{
    white-space: pre-wrap;
    width: 100%;
    font-family: Avenir;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    padding-bottom: 40px;
  }
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin-bottom: 40px;
}
.images{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.image{
  width: 33.33%;
  height: 309px;
  object-fit: cover;
}
