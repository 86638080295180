$input-width: 350px;
$input-height: 53px;

.card{
  width: 430px;
  height: 324px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  h1{
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffa1b8;
    margin: 30px 0 25px 0;
  }
  p{
    width: 340px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffa6b9;
    margin: 0 0 0 25px;
  }
}
.btnClose{
  width: 16px;
  height: 16px;
  background-color: rgba(255, 160, 163, 0.4);
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover{
    cursor: pointer;
  }
}
.field{
  width: $input-width;
  height: $input-height;
  border-radius: 6px;
  border: solid 1px #a6a6b4;
  margin-top: 10px;
  input{
    width: 100%;
    height: 55px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: Avenir-Roman;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a6a6b4;
    background-color: transparent;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
}
.resetPswBtn{
  width: 294px;
  height: 46px;
  border-radius: 6px;
  background-image: linear-gradient(288deg, #c7efff, #e1d0ff 43%, #ffa8d4);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  margin: 25px 0 25px 0;
  p{
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
}
