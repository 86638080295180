@import '../../components/commonStyle/common.scss';

.exploreMore-warp{
  white-space: pre-wrap;
  .exploreMore-h1{
    @include media-breakpoint-down(md) {
      font-size: 30px !important;
    }
  }
  .exploreMore-h2{
    @include media-breakpoint-down(md) {
      font-size: 20px !important;
    }
  }
  @include media-breakpoint-down(md) {
    width: 80%;
  }
}
.contentWrapper {
    @include media-breakpoint-down(md) {
      width: 80%;
    }
}
