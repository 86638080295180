.container{
  width: 1060px;
  height: 140px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  &:hover{
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease-in-out;
  }
  img{
    width: 151px;
    height: 100%;
    border-radius: 15px 0 0 15px;
    background-color: #666666;
    object-fit: cover;
  }
}
.infoArea{
  width: 909px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.row{
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 101px;
  box-sizing: border-box;
  p{
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0;
    color: #e679b1;
  }
}
.col{
  min-width: 334px;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
