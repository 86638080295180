@import '../../../../components/commonStyle/common.scss';
.container{
  width: 910px;
  @include media-breakpoint-down(md) {
    width: 90%;
  }
  height: 26px;
  border-radius: 13px;
  background-color: rgba(238, 163, 201, 0.3);
  display: flex;
  flex-direction: row;
}
.progressTxt{
  font-family: Avenir;
  font-size: 15px;
  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0;
  margin: 0;
}
