@import '../../components/commonStyle/common.scss';
.container{
  width: 100%;
  min-height: 100vh;
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.4) 82%, #ffffff);
}
.contents{
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  white-space: pre-line;
  box-sizing: border-box;
  @include media-breakpoint-down(md) {
    padding: 80px 0 80px 0;
  }
}
.title{
  text-shadow: 0 1px 7px #212529;
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0 0 60px 0;
}
.intro{
  text-shadow: 0 1px 7px #212529;
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin: 0 0 40px 0;
}
.btn{
  padding: 14px 40px;
  border-radius: 10px;
  border: solid 2px #96d8f3;
  background-color: #96d8f3;
  font-family: AvenirNext;
  font-size: 22px;
  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0;
  -webkit-transition: 0.3s;
  &:hover{
    cursor: pointer;
  }
  max-width: 80%;
}
