@import '../../../../../components/commonStyle/common.scss';
.container{
  width: 910px;
  padding: 0 60px 70px 60px;
  box-sizing: border-box;
  @include media-breakpoint-down(md) {
    width: 90%;
    padding: 0 10px 70px 10px;
  }
  border-radius: 30px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  white-space: pre-line;
  flex-direction: column;
  h1{
    
    font-family: Avenir;
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0 30px 0;
  }
}
.subtitle{
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
  margin: 0 0 10px 0;
}
.selectBox{
  width: 400px;
  height: 42px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  border-radius: 5px;
  border: solid 1px #e6e9eb;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 15px;
  box-sizing: border-box;
}
.fileName{
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  padding: 0;
  margin: 0;
}
.selectFileBtn{
  width: 95px;
  height: 25px;
  border-radius: 5px;
  background-color: #99d8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  p{
    font-family: Avenir;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
  &:hover{
    cursor: pointer;
  }
}
.selectedFiles{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 0;
}
.deleteIcon{
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin: 0 0 0 40px;
  &:hover{
    cursor: pointer;
  }
}
.instruction{
  width: 716px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  font-family: Avenir-Roman;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e679b1;
}
.inputFile{
  display: none;
}
