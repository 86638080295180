@import '../../components/commonStyle/common.scss';

.header-img-wrap{
  img{
    @include media-breakpoint-down(md) {
      max-width: 90px !important;
      max-height: 34px !important;
      margin: 0 12px !important;
    }
  }
}