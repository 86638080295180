@import "../../components/Header/Header.module";
@import '../../components/commonStyle/common.scss';

.container{
  width: 100%;
  position: relative;
}
.contents{
  width: 100%;
  min-height: 100vh;
  padding: $headerHeight 0 80px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.pageTitle{
  
  font-family: Avenir-Roman;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 60px 0 60px 0;
}
.storiesBoard{
  width: 1089px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    width: auto;
    flex-direction: column;
    align-items: center;
  }
}
.storyCard{
  width: 333px;
  height: 333px;
  border-radius: 10px;
  background-size: cover;
  background-color: rgba(154, 154, 170, 0.9);
  margin: 20px 15px 20px 15px;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
  &:hover{
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
  }
  div{
    width: 100%;
    height: 65px;
    opacity: 0.9;
    background-color: #9a9aaa;
    position: absolute;
    bottom: 0;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;
  }
  h1{
    max-width: 100%;
    font-family: Avenir;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 19px 0 0 0;
  }
  p{
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 5px 0 0 0;
  }
}
