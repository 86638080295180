@import '../../../../components/commonStyle/common.scss';

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: #fdf8f8;
  h1{
    
    font-family: Avenir-Roman;
    font-size: 42px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e679b1;
    margin: 40px 0;
  }
  p{
    font-family: AvenirNext;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #52585f;
    margin-top: 0px;
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }
  a {
    margin-bottom: 100px;
  }
}
.button{
  padding: 14px 40px;
  height: 61px;
  @include media-breakpoint-down(sm) {
    width: 300px;
  }
  border-radius: 10px;
  background-color: #99d8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 0 0;
  -webkit-transition: 0.3s;
  &:hover{
    cursor: pointer;
    background-color: #7dceef;
  }
  p{
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
}
