@import '../../../../components/commonStyle/common.scss';

$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 60px 0;
}
.storiesContainer{
  width: 82%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section {
  width: 100%;
}

@media(max-width: $phone) {
  .section {
    height: 100%;
  }
  .storiesContainer {
    width: 100%;
    display: block;
  }
}
.card{
  width: 360px;
  height: 437px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
  margin: 20px auto;
  flex-grow: 0;
  flex-basis: auto;
  padding: 0;
  &:hover{
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
  }
  img{
    width: 100%;
    height: 200px;
    background-color: grey;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
  }
}
.cardIntro{
  width: 100%;
  height: 57%;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  h1{
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e679b1;
    margin: 20px 0 20px 0;
  }
  p{
    max-height: 100px;
    font-family: Avenir;
    font-size: 16px;
    text-align: left;
    line-height: initial;
    color: #52585f;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
.footer{
  left: 0;
  bottom: 0;
  width: 100%;
  height: 53px;
  background-color: #c3e8f7;
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  text-shadow: 0 1px 7px #212529;
  font-family: AvenirNext;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
  padding-left: 20px;
  &:hover{
    cursor: pointer;
    text-decoration-line: underline;
  }
  p {
    height: 100%;
    margin-top: 15px;
  }
}
.viewAll{
  font-family: AvenirNext;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #99d8f1;
  margin: 30px;
  &:hover{
    cursor: pointer;
    text-decoration-line: underline;
  }
  @include media-breakpoint-down(md) {
    margin: 30px 0 50px 0;
  }
}
