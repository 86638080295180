.container{
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 50px auto;
}
.category{
  display: flex;
  flex-direction: row;
  p{
    padding: 3px 10px;
  }
  span{
    padding: 3px 10px;
  }
}
.unselected{

}
.selected{
  border-radius: 4px;
  background-color: rgba(238, 163, 201, 0.86)
}
.filterOptions{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  p{
    &:hover{
      cursor: pointer;
    }
  }
}
