.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contents{
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0 0 0;
  white-space: pre-line;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 0 0 60px 0;
}
.intro{
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52585f;
  margin: 0 0 50px 0;
}
.images{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 50px 0;
}
.image{
  width: 33.33%;
  height: 309px;
  background-color: #eeeeee;
  object-fit: cover;
}
