
.spin-loading{
  .loading-icon{
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    z-index: 200;
    &::after{
      content: 'Loading...';
      color: #1890ff;
      position: relative;
      left: -10px;
      top: 4px;
    }
  }
}
