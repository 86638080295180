.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
}
.contents{
  width: 1060px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0 100px 0;
  white-space: pre-wrap;
}
.title{
  
  font-family: Avenir-Roman;
  font-size: 42px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e679b1;
  margin: 60px 0;
}
.processContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.processBubble{
  width: 700px;
  height: 74px;
  border-radius: 46px;
  box-shadow: 0 2px 8px 0 rgba(238, 163, 201, 0.3);
  background-color: rgba(238, 163, 201, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    max-width: 521px;
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}
.iconNext{
  width: 42px;
  height: 40px;
  margin: 10px 0 10px 0;
  box-shadow: 0 2px 4px 0 rgba(195, 232, 247, 0.3);
  background-color: #c3e8f7;
}
.questionWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  padding: 20px 0 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e9eb;
  p {
    margin-block-start: 1em;
  }
}
.question{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: pre-wrap;
  &:hover{
    cursor: pointer;
  }
  p{
    font-family: Avenir;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e679b1;
    padding: 0;
    margin: 0;
  }
  img{
    width: 12px;
    height: 18px;
    transform: rotate(180deg);
    -webkit-transition: 0.3s;
  }
}
.answer{
  font-family: Avenir;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #52585f;
  margin-bottom: 0px;
}
